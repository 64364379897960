import { useRef, useEffect, useState, FC } from "react";
import { space } from "@styles";
import styled from "styled-components";

// Inspiration for using useEffect to load the script: https://stackoverflow.com/questions/64297146/use-useeffect-to-load-a-script
// This was required when upgrading to React 18, to fix this error: Hydration failed because the initial UI does not match what was rendered on the server.

// Extend window interface to include Trustpilot object
declare global {
  interface Window {
    Trustpilot: any;
  }
}

interface TrustWidgetLargeProps {
  mt?: string;
}

export const TrustWidgetLarge: FC<TrustWidgetLargeProps> = ({ mt }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasScriptLoaded, setHasScriptLoaded] = useState(false);

  /**
   * Extra security measure to check if the script has
   * already been included in the DOM
   */
  const scriptAlreadyExists = () => document.querySelector("script#tp-widget") !== null;

  const appendTrustpilotScript = () => {
    const script = document.createElement("script");
    script.id = "tp-widget";
    script.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    script.defer = true;
    script.addEventListener("load", () => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true);
      }
    });
    document.body.append(script);
    setHasScriptLoaded(true);
  };

  useEffect(() => {
    if (!hasScriptLoaded) {
      appendTrustpilotScript();
    }

    setHasLoaded(true);
  }, [hasScriptLoaded]);

  const ref = useRef<HTMLDivElement>(null);

  if (!hasLoaded) {
    return null;
  }

  return (
    <Box
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5baa48c16b01ec0001b76165"
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
      data-review-languages="en"
      mt={mt}
    >
      <a
        href="https://uk.trustpilot.com/review/www.yulife.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </Box>
  );
};

export default TrustWidgetLarge;

const Box = styled.div<{
  mt?: string;
}>`
  position: relative;
  margin-top: ${(p) => (p.mt ? p.mt : space.S40)};

  @media only screen and (max-width: 50rem) {
    margin-top: ${(p) => (p.mt ? p.mt : space.S32)};
  }
`;
