import { FC } from "react";
import styled from "styled-components";
import { Link } from "@components/atoms";
import { color } from "../../styles";
import { mobile, tablet } from "../templates/Breakpoints";
import { useBreakpoints, useLocale } from "hooks";
import { BetterButton } from "components/atoms/BetterButton";
import { ClientOnly } from "@components/templates";
import { StaticRoutes } from "@routes";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { convertNewlinesToBreaks } from "utils/string";
export interface RequestCallbackProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonVariant?: "primary" | "secondary" | "inverted" | "purple" | "menu";
}

export const RequestCallback: FC<RequestCallbackProps> = (props) => (
  <ClientOnly>
    <_RequestCallback {...props} />
  </ClientOnly>
);

const _RequestCallback: FC<RequestCallbackProps> = ({
  title,
  subtitle,
  buttonText,
  buttonVariant = "primary",
}) => {
  const { isMobile } = useBreakpoints();
  // const t = useTranslations("footer");
  const { locale } = useLocale();

  // doing a quick fix - will revisit

  const ukDefault =
    "Get a free quote and find out how our simple and affordable group insurance can help your employees live healthier and happier lives.";
  const otherLocaleDefault =
    "Get in touch to find out how we can help your employees live healthier and happier lives.";

  const defaultSubtitle = locale === "uk" ? ukDefault : otherLocaleDefault;

  // doing a quick fix - will revisit
  // const defaultText = {
  //   title: t<string>("request-call-back.title"),
  //   subtitle: t<string>("request-call-back.subtitle"),
  //   buttonText: t<string>("request-call-back.cta"),
  // };

  const defaultText = {
    title: "Ready to hear how we can help?",
    subtitle: defaultSubtitle,
    buttonText: "Speak to our team",
  };

  return (
    <CallbackWrap>
      <Background>
        <TextBlock>
          <Title locale={locale}>{convertNewlinesToBreaks(title) ?? defaultText.title}</Title>
          <Subtitle>{convertNewlinesToBreaks(subtitle) ?? defaultText.subtitle}</Subtitle>
          <Link href={StaticRoutes.getAQuote} legacyBehavior>
            <BetterButton
              variant={buttonVariant}
              text={buttonText ?? defaultText.buttonText}
              width={isMobile ? "100%" : "fit-content"}
            />
          </Link>
        </TextBlock>
        <YugiWrapper>
          <Img
            height={421}
            width={336}
            locale={locale}
            src="/static/giraffes/YugiAtHelpDesk.svg"
            alt="Yugi at a computer with a headset, ready to help"
          />
        </YugiWrapper>
      </Background>
    </CallbackWrap>
  );
};

const Background = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 58rem;
  background: ${color.S310};
  border-radius: 0.5rem;
  border: 1px solid ${color.S3100};
  gap: 1rem;

  ${mobile} {
    border-radius: 0;
    display: block;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2.5rem 0 2.5rem 2rem;

  ${mobile} {
    margin-right: 2rem;
  }
`;

const Title = styled.h2<{ locale: string }>`
  letter-spacing: 0;
  font-size: ${(p) => (p.locale === "uk" ? "2.5rem" : "3.5rem")};
  margin: 0;
  max-width: ${(p) => (p.locale === "us" ? "29rem" : "32rem")};
  color: ${color.S3100};

  ${tablet} {
    font-size: 2.5rem;
  }

  ${mobile} {
    width: 100%;
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  max-width: 32rem;
  font-size: 1.25rem;
  letter-spacing: 0.075rem;
  margin: 0.938rem 0 2rem;

  @media only screen and (max-width: 50rem) {
    max-width: 40rem;
  }
`;

const Img = styled(Image)<{ locale: string }>`
  position: relative;
  bottom: ${(p) => (p.locale === "uk" ? "2.8rem" : "2.6rem")};
  margin-right: 1.6rem;

  ${tablet} {
    position: static;
    max-width: 18rem;
    height: auto;
  }

  @media only screen and (max-width: 65rem) {
    max-width: 16rem;
  }

  ${mobile} {
    display: none;
  }
`;

const YugiWrapper = styled.div`
  height: 0; // surpress height so yugi doesn't force the div to grow

  ${tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
`;

export const CallbackWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 8rem;

  ${tablet} {
    padding: 2rem 2rem;
  }
`;
