import { FC } from "react";
import styled from "styled-components";
import { color } from "@styles";
import { mobile, tablet } from "components/templates/Breakpoints";
import Image from "next/image";

export interface KeyResourcesItemProps {
  tag: string;
  contentType?: string;
  readTime: string;
  title: string;
  slug: string;
  img?: string;
  alt?: string;
}

export const KeyResourcesItem: FC<KeyResourcesItemProps> = ({
  tag,
  contentType,
  readTime,
  title,
  slug,
  img,
  alt,
}) => {
  return (
    <a href={slug}>
      <Container>
        {img && <Img src={img} width={348} height={192} alt={alt || ""} quality={90} />}
        <PillWrap>
          {tag && <Pill type="tag">{tag}</Pill>}
          {contentType && <Pill type="content">{contentType}</Pill>}
          <Text>{readTime}</Text>
        </PillWrap>
        <Title>{title}</Title>
      </Container>
    </a>
  );
};

interface PillProps {
  type: "tag" | "content";
}

const PillWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;
  width: 21.75rem;
  row-gap: 0.75rem;

  ${tablet} {
    width: 17.4rem;
  }
`;

const Container = styled.div`
  width: 21.75rem;

  ${tablet} {
    width: 17.4rem;
  }
`;

const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.075rem;
`;

const Title = styled.p`
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  text-align: left;
  display: inline-block;

  ${mobile} {
    font-size: 1.3rem;
  }
`;

const Img = styled(Image)`
  object-fit: cover;
  height: 12rem;
  width: 21.75rem;
  border-radius: 0.5rem;
  border: 0.125rem ${color.B240} solid;
  box-shadow: 0.5rem 0.5rem 0 0 ${color.B220};

  ${tablet} {
    height: 9.6rem;
    width: 17.4rem;
  }
`;

const Pill = styled.div<PillProps>`
  border-radius: 0.5rem;
  padding: 0.063rem 1rem 0.25rem 1rem;
  height: 1.5rem;
  margin-right: 1rem;
  display: inline-block;
  color: ${(p) => (p.type === "tag" ? `${color.S3100}` : `${color.B1100}`)};
  background: ${(p) => (p.type === "tag" ? `${color.S310}` : "#F2F2FA")};
  bordercolor: ${(p) => (p.type === "tag" ? `${color.S330}` : `${color.B240}`)};
  borderwidth: 1px;
  borderstyle: solid;
`;
